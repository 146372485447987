import type { Locale } from 'lc-services/types'
import type { RouteLocation } from 'vue-router'

export const createSlugCacheHouse = (route: RouteLocation, locale: Locale) => {
  const privateToken = route?.query['private-token'] || null
  const slug = route?.params?.slug
  const queryFetch = privateToken ? `?private-token=${privateToken}` : ''

  return `/api/${locale}/house/${slug}${queryFetch}` as const
}

export const createSlugCacheHouseTransaction = (
  route: RouteLocation,
  locale: Locale,
) => {
  const privateSaleToken = route?.query['private-sale-token'] || null
  const slug = route?.params?.slug
  const queryFetch = privateSaleToken
    ? `?private-sale-token=${privateSaleToken}`
    : ''

  return `/api/${locale}/house/transaction/${slug}${queryFetch}` as const
}
